import { Injectable } from "@angular/core";
import { HttpService } from "@app/core/http";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs";
import {
  AcknowledgeEvaluationForm,
  EvaluatedByList,
  FetchEvaluationForm,
  ResetEvaluationAddEditList,
  ResetEvaluationByList,
  SubmitEvaluationForm,
} from "../actions/evaluation.action";

interface IEvaluationStateModel {
  evaluationByList: IEvaluatedByList[] | null;
  evaluationForm: IEvaluationForm | null;
  evaluationByListCount: number;
}

@State<IEvaluationStateModel>({
  name: "EvaluationState",
  defaults: {
    evaluationByList: [],
    evaluationForm: null,
    evaluationByListCount: 0,
  },
})
@Injectable()
export class EvaluationState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static evaluatedByList(state: IEvaluationStateModel) {
    return state.evaluationByList;
  }
  @Selector()
  static evaluationByListCount(state: IEvaluationStateModel) {
    return state.evaluationByListCount;
  }

  @Selector()
  static evaluationForm(state: IEvaluationStateModel) {
    return state.evaluationForm;
  }

  @Action(EvaluatedByList)
  EvaluatedByList(
    ctx: StateContext<IEvaluationStateModel>,
    { param }: EvaluatedByList
  ) {
    return this._http.post("evaluation/list", param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          evaluationByList: resultData.evaluation,
          evaluationByListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(FetchEvaluationForm)
  FetchEvaluationForm(
    ctx: StateContext<IEvaluationStateModel>,
    { param }: FetchEvaluationForm
  ) {
    return this._http.post("evaluation/get-evaluation-form", param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          evaluationForm: resultData,
        });
      })
    );
  }

  @Action(SubmitEvaluationForm)
  SubmitEvaluationForm(
    ctx: StateContext<IEvaluationStateModel>,
    { param }: SubmitEvaluationForm
  ) {
    return this._http.post("evaluation/add-edit", param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {});
      })
    );
  }

  @Action(AcknowledgeEvaluationForm)
  AcknowledgeEvaluationForm(
    ctx: StateContext<IEvaluationStateModel>,
    { param }: AcknowledgeEvaluationForm
  ) {
    return this._http.post("evaluation/acknowledge", param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {});
      })
    );
  }

  @Action(ResetEvaluationByList)
  ResetEvaluationByList(
    ctx: StateContext<IEvaluationStateModel>,
    { param }: ResetEvaluationByList
  ) {
    ctx.patchState({
      evaluationByList: param,
    });
  }

  @Action(ResetEvaluationAddEditList)
  ResetEvaluationAddEditList(
    ctx: StateContext<IEvaluationStateModel>,
    { param }: ResetEvaluationByList
  ) {
    ctx.patchState({
      evaluationForm: param,
    });
  }
}
