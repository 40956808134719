export class EvaluatedByList {
  static readonly type = "[EvaluatedByList] Post";
  constructor(public param: { user_id: number }) {}
}

export class FetchEvaluationForm {
  static readonly type = "[FetchEvaluationForm] Fetch";
  constructor(
    public param: { user_id: number; evaluated_by: number; type: string }
  ) {}
}

export class SubmitEvaluationForm {
  static readonly type = "[SubmitEvaluationForm] Post";
  constructor(public param: IEvaluationSubmitParam) {}
}

export class ResetEvaluationByList {
  static readonly type = "[ResetEvaluationByList] Post";
  constructor(public param: null) {}
}

export class ResetEvaluationAddEditList {
  static readonly type = "[ResetEvaluationAddEditList] Post";
  constructor(public param: null) {}
}

export class AcknowledgeEvaluationForm {
  static readonly type = "[AcknowledgeEvaluationForm] Post";
  constructor(public param: FormData) {}
}
